import whats_pro from '../assets/svg/projects/whats_pro.png'
import sim_sys from '../assets/svg/projects/sim_sys.png'
import e_pro from '../assets/svg/projects/e_pro.png'
import sas_manager from '../assets/svg/projects/sas_manager.png'
import sas_pro from '../assets/svg/projects/sas_pro.png'
import eshtraky from '../assets/svg/projects/eshtraky.png'
import pro_caffe from '../assets/svg/projects/pro_caffe.png'
import ucp_free from '../assets/svg/projects/ucp_free.png'
import ucp_pro from '../assets/svg/projects/ucp_pro.png'
import foot_ball from '../assets/svg/projects/foot_ball.png'
import akoam_light from '../assets/svg/projects/akoam_light.png'
import java_cards from '../assets/svg/projects/java_cards.png'
import google_pay_apps from '../assets/svg/projects/google_pay_apps.png'


export const projectsData = [
    {
        id: 1,
        projectName: 'Whats PRO',
        projectDesc: 'WhatsPro is a service that enables you to send WhatsApp messages using a phone number of your choice and at your disposal, in addition to the possibility of having your own API that you can use wherever you want',
        tags: ['Angular', 'Laravel', 'NodeJs','MySQL'],
        code: 'https://www.youtube.com/playlist?list=PL8wL0kHXJLvSVoBqS0ForzAgHHdns5e2Q',
        demo: 'http://whats-pro.net',
        has_video:true,
        only_code:false,
        image: whats_pro
    },
    {
        id: 2,
        projectName: 'SIM MGMT SYS',
        projectDesc: 'SIM Management System and control of all accounts, bills, settings and Internet packages with a record of operations and a complete control panel for managers and subscribers',
        tags: ['Angular', 'PHP', 'MySQL','user-pass:admin'],
        // code: 'https://www.youtube.com/playlist?list=PL8wL0kHXJLvSVoBqS0ForzAgHHdns5e2Q',
        demo: 'http://sim.pro-service.link/',
        image: sim_sys
    },
    {
        id: 3,
        projectName: 'Eshtraky PRO',
        projectDesc: 'This project involves the development of an Android application for viewing and managing Internet Account Data.',
        tags: ['Flutter', 'Api', 'PHP', 'MySQL'],
        code: 'https://www.youtube.com/watch?v=9DK2OQYftKo',
        demo: 'https://play.google.com/store/apps/details?id=com.snonosystems.eshtraky_pro',
        has_video:true,
        image: e_pro
    },
    {
        id: 4,
        projectName: 'Eshtraky',
        projectDesc: 'The Free Version Of Eshtraky PRO App',
        tags:  ['Java', 'Api', 'PHP', 'MySQL'],
        code: 'https://www.youtube.com/watch?v=bkDNDfSG2Kg',
        demo: 'https://play.google.com/store/apps/details?id=com.snonosystems.eshtraky_pro',
        has_video:true,
        image: eshtraky
    },
    {
        id: 5,
        projectName: 'SAS PRO App',
        projectDesc: 'Android App For SAS4 Radius, A Powerful Internet MGMT SYS and One of Snono-Systems in Iraq Known as a company for Software Consulting And Development For Your Digital Success',
        tags: ['Java', 'Api', 'PHP', 'MySQL'],
        code: 'https://www.youtube.com/watch?v=TqjDPkJzISM',
        demo: 'https://play.google.com/store/apps/details?id=com.snonosystems.sas4manager2',
        has_video:true,
        image: sas_pro
    },
    {
        id: 6,
        projectName: 'SAS4 Manager App',
        projectDesc: 'The Free Version Of SAS PRO App',
        tags:['Java', 'Api', 'PHP', 'MySQL'],
        code: 'https://youtu.be/kv2EVlcQBWQ',
        demo: 'https://play.google.com/store/apps/details?id=com.snonosystems.sas4_manager',
        has_video:true,
        image: sas_manager
    },

    {
        id: 7,
        projectName: 'CAFFE APP',
        projectDesc: 'The Caffe Manager is separate from the SAS PRO application and is responsible for distributing and printing cards, managing accounts and many other features inside.',
        tags:['Java', 'Api', 'PHP', 'MySQL'],
        code: 'https://www.youtube.com/watch?v=xgPPrJJELEw',
        demo: 'https://play.google.com/store/apps/details?id=com.snonosystems.sas_pro_caffe',
        has_video:true,
        image: pro_caffe
    },
    {
        id: 8,
        projectName: 'About 80 App On Play',
        projectDesc: 'For private companies, Private Android Apps That Depends On Pro Services',
        tags:['Java', 'Api', 'PHP', 'MySQL'],
        code: '',
        demo: 'https://play.google.com/store/apps/collection/cluster?clp=igM4ChkKEzYwMjQ2NDMzMDc4NDU0NDk3MTUQCBgDEhkKEzYwMjQ2NDMzMDc4NDU0NDk3MTUQCBgDGAA%3D:S:ANO1ljLJwWM&gsr=CjuKAzgKGQoTNjAyNDY0MzMwNzg0NTQ0OTcxNRAIGAMSGQoTNjAyNDY0MzMwNzg0NTQ0OTcxNRAIGAMYAA%3D%3D:S:ANO1ljJA_v4',
        image: google_pay_apps
    },
    {
        id: 9,
        projectName: 'UCP Internet',
        projectDesc: `User Control Panel for users to manage their internet accounts <br> user-pass=test <br> code=6049`,
        tags:['html/css/js', 'bootstrap', 'PHP/laravel', 'api'],
        code: 'https://www.youtube.com/watch?v=l4WlKzRahiE',
        demo: 'http://snono.org/',
        image: ucp_free,
        has_video: true
    },
    {
        id: 10,
        projectName: 'UCP PRO',
        projectDesc: `User Control Panel PRO Version for users to manage their internet accounts <br> user-pass=test <br> code=6049`,
        tags:['angular', 'laravel', 'mysql', 'api'],
        code: 'https://www.youtube.com/watch?v=zlaoVQZLYnw',
        demo: 'http://snono.org/user-pro/',
        image: ucp_pro,
        has_video: true
    },
    {
        id: 11,
        projectName: 'Akoam Light Version',
        projectDesc: `Akoam Light is a simulation of parsed html data using php backend from another website <a href="https://akwam.to/" target="_blank"> Click Here To Preview the original website</a>`,
        tags:['angular', 'php', 'data simulation'],
        demo: 'http://akoamlite.xyz/',
        image: akoam_light,
    },
    {
        id: 12,
        projectName: 'Football MGMT SYS',
        projectDesc: `A huge system written in JAVA PURE CONSOLE, and it was handled very well with a good documentation`,
        tags:['JAVA','Console'],
        demo: 'https://github.com/h4775346/football_management_system',
        image: foot_ball,
        only_code:true,
    },
    {
        id: 12,
        projectName: 'Football MGMT SYS',
        projectDesc: `WIFI Cards Thermal Printer For Caffe Using JAVA GUI SWING`,
        tags:['JAVA','GUI','Swing'],
        demo: 'https://pro-service.link/card-manager/CardManagerFull.exe',
        image: java_cards,
    },
]
