import acpc_local from '../assets/png/acpc_local.jpg'
import android from '../assets/png/android_java.jpg'
import ecpc from '../assets/png/ecpc.png'
import full_stack from '../assets/png/full_stack.jpg'

export const achievementData = {
    bio : "As a software engineer, I spent a lot of time struggling to develop myself, develop the capabilities I have, rehabilitate my mind, and improve my skills, so I participated in collecting some achievements that have helped me rise to what I am now.",
    achievements : [
        {
            id : 1,
            title : 'CPC Certification',
            details : 'Collegiate Programming Contest,Provides college students with opportunities to interact with each other to decide the best team selected to compete in the Egyptian competitions',
            date : 'Sep 20, 2019',
            field : 'Problem Solving',
            image : acpc_local
        },

        {
            id : 2,
            title : 'ECPC Certification',
            details : 'Egyptian Collegiate Programming Contest, Provides college students with opportunities to interact with students from other universities and to sharpen and demonstrate their problem-solving, programming, and teamwork skills',
            date : 'Oct 26, 2019',
            field : 'Problem Solving',
            image : ecpc
        },
        {
            id : 3,
            title : 'Android JAva Kotlin Certification',
            details : 'Altras Certificate for completing Android Java Kotlin Development Course',
            date : 'Oct 10, 2019',
            field : 'Android',
            image : android
        },
        {
            id : 4,
            title : 'Full Stack Web Certification',
            details : 'Russian Certificate for completing Full Stack Web Development Course',
            date : 'Oct 20, 2020',
            field : 'Full Stack',
            image : full_stack
        },

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/
