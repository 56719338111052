import resume from '../assets/pdf/Abanoub Hany Resume.pdf'
import me from '../assets/png/me.png'


export const headerData = {
    name: 'Abanoub Hany',
    title: "Software Engineer",
    desciption:"Dont't worry if it doesn't work right.If everything did, you'd be out of a job, So programming language is for thinking about programs, not for expressing programs you've already thought of. It should be a pencil, not a pen",
    image:me ,
    resumePdf: resume
}
