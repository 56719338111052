export const experienceData = [
    {
        id: 1,
        company: 'Free Lancing',
        jobtitle: 'Java Kotlin Android Developer',
        startYear: '2017',
        endYear: '2019'
    },
    {
        id: 2,
        company: 'PRP Services',
        jobtitle: 'c++ python and Full Stack Developer',
        startYear: '2019',
        endYear: '2021'
    },
    {
        id: 3,
        company: 'PRO Services',
        jobtitle: 'Software Engineer',
        startYear: '2021',
        endYear: 'Present'
    },
]
