export const educationData = [
    {
        id: 1,
        institution: 'El haditha Middle School',
        course: 'Higher Secondary Education',
        startYear: '2007',
        endYear: '2015'
    },
    {
        id: 2,
        institution: 'Refaa Eltahtawy Primary School',
        course: 'Higher Primary Education',
        startYear: '2015',
        endYear: '2018'
    },
    {
        id: 3,
        institution: 'Thebes Higher Institute',
        course: 'Bachelor of Computer Science and Information Technology',
        startYear: '2018',
        endYear: '2022'
    },
]
