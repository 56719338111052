/* eslint-disable */

import {Android, Code, Edit} from "@material-ui/icons";
import { CgBrowser, TiFlowChildren} from "react-icons/all";



export const servicesData = [
    // {
    //     id: 1,
    //     title: 'Marketing',
    //     icon: <BiShoppingBag/>
    // },
    {
        id: 1,
        title: 'Android Dev',
        icon: <Android  />
    },
    {
        id: 2,
        title: 'Web Dev',
        icon: <CgBrowser />
    },
    {
        id: 3,
        title: 'Software ENG',
        icon: <Code />
    },
    {
        id: 4,
        title: 'Systems analysis',
        icon: <Edit />
    },
    {
        id: 5,
        title: 'Systems Design',
        icon: <TiFlowChildren />
    },


]
